import $ from 'jquery';
import "/modules/reveal-modal";
import { ajax } from "/js/utility/ajax";
require("jquery-validation");

$(document).on('click', '.login-forgot-password', function (e) {
	e.preventDefault();

	if ($('#login-form').is(':hidden')) {
		$("#login-form").removeClass("is-hidden");
		$("#forgot-password-form").addClass("is-hidden");
		$("#login-username").focus();
	} else {
		$("#login-form").addClass("is-hidden");
		$("#forgot-password-form").removeClass("is-hidden");
		$("#forgot-password-username").val($("#login-username").val());
		$("#forgot-password-username").focus();
	}
});

$("#ForgotPasswordForm").on("submit", function (e) {
	e.preventDefault();
	var t = $(this);
	$("#forgot-password-username").parent().removeClass("form-validation-error");
	ajax({
		url: t.attr("action"),
		data: {
			SearchStr: $("#forgot-password-username").val(),
		},
		wrapSuccess: false,
		success: function (data) {
			if (data.Status === "Ok") {
				$("#login-notification-text").text(data.Message).closest(".alert").removeClass("is-hidden");
				$(".login-forgot-password").first().trigger("click");
			} else {
				$("#resetpassword-error-message").text(data.Message).closest("div").addClass("form-validation-error");
			}
		},
	});
});

$('#validate-email-form').on('submit', function (e) {
	e.preventDefault();
	$('.form-validation-error').removeClass("form-validation-error");
	$('.signup-notification').addClass("is-hidden");

	var f = $(this);

	if (f.valid()) {
		ajax({
			form: f,
			wrapSuccess: false,
			success: function (data) {
				if (data.Status === "Ok") {
					$("#validate-email-form-div").addClass("is-hidden");
					$("#validate-email-sentmessage-div").removeClass("is-hidden");
				} else {
					$('#signup-notification-text').text(data.Message);
					$('.signup-notification').removeClass("is-hidden");
				}
			},
		});
	}
});

$("#signup-form").on('submit', function (e) {
	e.preventDefault();

	$('.form-validation-error').removeClass("form-validation-error");
	$('.signup-notification').addClass("is-hidden");

	var f = $("#signup-form");

	if (f.valid()) {

		ajax({
			form: f,
			wrapSuccess: false,
			success: function (data) {
				if (data.Status === "Ok") {
					// can we do like "reset()" on the form?

					$('#login-username').val($("#Signup_Username").val());
					$('#password').val('');
					$('#sign-up-modal').trigger('reveal:close');
					$('#login-signup-notification-text').text(data.Message);
					$('#login-signup-notification')
						.removeClass('is-hidden')
						.toggleClass("alert-notification", data.Object.IsInformationMessage)
						.toggleClass("alert-success ", !data.Object.IsInformationMessage);


				} else {
					$('#signup-notification-text').text(data.Message);
					$('.signup-notification').removeClass("is-hidden");
				}
			},
		});
	}
});

$(document).on('click', '.login-cancel', function (e) {
	e.preventDefault();
	$('#sign-up-modal').trigger('reveal:close');
});

$(document).on('click', '.terms-close', function (e) {
	e.preventDefault();
	$('#terms-modal').trigger('reveal:close');
});

$(document).on('click', '#sign-up-modal .close-reveal-modal', function (e) {
	e.preventDefault();
	$("#sign-up-modal").trigger('reveal:close');
});
